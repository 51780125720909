<template>
  <div id="confirmation" class="middle-box text-center animated fadeInDown">
    <div>

      <div>
        <img src="@public/img/eurofiscalis/logo.png" alt="Eurofiscalis">
      </div>

      <div class="ibox-content m-t">

        <div class="widget style1 navy-bg">
          <div class="row vertical-align">
            <div class="col-3">
              <i class="fa fa-check fa-2x"></i>
            </div>
            <div class="col-9">
              <h4 class="font-bold">{{$t('eurofiscalis_workers.confirmation.payment-confirmed')}}</h4>
            </div>
          </div>
        </div>

        <div class="form-group">
          {{$t('eurofiscalis_workers.confirmation.content')}}
        </div>
        <button class="btn btn-primary" type="button" @click="onLoginButtonClick">{{ $t('profile.info.login-button') }}</button>

      </div>

      <p class="m-t">
          <small>{{ $t("footer.copyright", [year]) }}</small>
      </p>

    </div>
  </div>

</template>

<style scoped>
  
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';

@Component({
})
export default class Confirmation extends mixins(GenericPage) {
  
  year = (new Date()).getFullYear();

  onLoginButtonClick() {
    this.$router.push('../login').catch(err => {});
  }
  
}
</script>